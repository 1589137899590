import { Typography, TypographyProps, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';

export interface ActionTitleProps extends Omit<TypographyProps, 'children'> {
  coloredWords?: number;
  label: string;
}

const Title = styled(Typography)(({ theme }) => {
  return {
    cursor: 'default',
    margin: `0 ${theme.spacing(4)}`,
    [theme.breakpoints.up('md')]: {
      margin: `0`,
    },
    '&, & > .colored': {
      fontSize: theme.typography.h1.fontSize,
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      textAlign: 'left',
    },
    '& > .colored': {
      color: theme.palette.primary.main,
    },
  };
});

const ActionTitle: React.FC<ActionTitleProps> = ({ label, coloredWords, ...props }) => {
  const [uncoloredLabel, setUncoloredLabel] = useState('');
  const [coloredLabel, setColoredLabel] = useState('');
  useEffect(() => {
    if (coloredWords) {
      const words = label.split(' ');
      const wordsToColor = words.splice(words.length - coloredWords, coloredWords);
      setColoredLabel(wordsToColor.join(' '));
      setUncoloredLabel(words.join(' '));
    } else {
      setColoredLabel('');
      setUncoloredLabel(label);
    }
  }, [setColoredLabel, setUncoloredLabel, label, coloredWords]);
  return (
    <Title {...props}>
      {uncoloredLabel}{' '}
      <Typography className="colored" component={'span'}>
        {coloredLabel}
      </Typography>
    </Title>
  );
};

export default ActionTitle;
