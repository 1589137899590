import { styled } from '@mui/material';
import React from 'react';

interface SvgProps {}

const SvgIcon = styled('div')(() => {
  return {
    '& svg': {
      width: '100%',
      height: '20px',
    },
  };
});

// for some reason if I import  MaxLogo from '~src/images/svg/max-logo.svg'; and use it,
// the styles don't affrect 'svg' in that file. I have to use it as code here.
// Need to investigate and tyry to fix. Currently, we have code duplication
const MaxLogoIcon: React.FC<SvgProps> = () => {
  return (
    <SvgIcon>
      <svg
        width="206"
        height="69"
        viewBox="0 0 206 69"
        preserveAspectRatio="xMidYMid meet"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M178.372 43.2144C176.538 43.2144 174.678 42.6325 173.1 41.4282C169.284 38.5054 168.556 33.0386 171.469 29.2092L190.4 4.33804C193.312 0.508596 198.759 -0.222112 202.575 2.70072C206.391 5.62355 207.119 11.0903 204.207 14.9198L185.276 39.7909C183.563 42.0371 180.988 43.2144 178.372 43.2144Z"
          fill="url(#paint0_linear_1366_38919)"
        />
        <path
          d="M197.317 68.0855C194.701 68.0855 192.125 66.9082 190.413 64.662L171.482 39.7909C168.57 35.9614 169.298 30.4946 173.114 27.5718C176.929 24.649 182.377 25.3797 185.289 29.2091L204.22 54.0803C207.133 57.9097 206.404 63.3765 202.589 66.2993C201.011 67.5036 199.164 68.0855 197.317 68.0855Z"
          fill="url(#paint1_linear_1366_38919)"
        />
        <path
          d="M186.193 38.316C186.193 38.316 186.206 38.2889 186.22 38.2754C186.233 38.2483 186.247 38.2213 186.26 38.1807C186.786 37.044 187.056 35.8262 187.069 34.6083C187.069 34.5677 187.069 34.5271 187.069 34.4865C187.069 34.4459 187.069 34.4189 187.069 34.3783C187.056 33.2146 186.799 32.0373 186.314 30.9412C186.3 30.9006 186.274 30.8601 186.26 30.833C186.247 30.8059 186.233 30.7653 186.22 30.7383C186.22 30.7383 186.22 30.7112 186.206 30.7112C185.936 30.1429 185.599 29.6016 185.195 29.1009L166.358 4.36514C163.446 0.535696 157.999 -0.195012 154.183 2.72782C150.367 5.65065 149.639 11.1174 152.551 14.9469L164.161 30.197C166.102 32.7545 166.102 36.2998 164.161 38.8572L152.551 54.1074C149.639 57.9368 150.367 63.4036 154.183 66.3264C155.76 67.5307 157.608 68.1126 159.455 68.1126C162.071 68.1126 164.646 66.9353 166.358 64.6891L185.195 39.9533C185.586 39.4526 185.923 38.9114 186.206 38.3566L186.193 38.316Z"
          fill="url(#paint2_linear_1366_38919)"
        />
        <path
          d="M9.34259 68.0855C4.54244 68.0855 0.65918 64.1884 0.65918 59.3711V9.62891C0.65918 4.81166 4.54244 0.914551 9.34259 0.914551C14.1427 0.914551 18.026 4.81166 18.026 9.62891V59.3711C18.026 64.1884 14.1427 68.0855 9.34259 68.0855Z"
          fill="url(#paint3_linear_1366_38919)"
        />
        <path
          d="M32.2242 43.2143C30.1208 43.2143 28.0039 42.443 26.3319 40.9004C22.8127 37.6393 22.5835 32.1184 25.8465 28.5866L48.7281 3.71553C51.9776 0.183774 57.4789 -0.0462628 60.9981 3.22839C64.5173 6.48951 64.7465 12.0104 61.4835 15.5422L38.6019 40.4133C36.8895 42.2671 34.5569 43.2143 32.2242 43.2143Z"
          fill="url(#paint4_linear_1366_38919)"
        />
        <path
          d="M32.2375 43.2144C29.9048 43.2144 27.5721 42.2672 25.8597 40.4133L2.97816 15.5422C-0.271374 12.0105 -0.0556361 6.48956 3.46357 3.22844C6.98277 -0.0326848 12.4841 0.183821 15.7336 3.71557L38.6152 28.5867C41.8647 32.1184 41.649 37.6393 38.1298 40.9005C36.4578 42.4431 34.3409 43.2144 32.2375 43.2144Z"
          fill="url(#paint5_linear_1366_38919)"
        />
        <path
          d="M0.497314 35.339C0.551249 31.7261 2.77604 28.5191 6.13345 27.22C9.49085 25.921 13.2932 26.8005 15.7472 29.4392L0.65911 9.64246L0.497314 35.3525V35.339Z"
          fill="url(#paint6_linear_1366_38919)"
        />
        <path
          d="M55.1192 68.0855C50.319 68.0855 46.4358 64.1884 46.4358 59.3711V9.62891C46.4358 4.81166 50.319 0.914551 55.1192 0.914551C59.9193 0.914551 63.8026 4.81166 63.8026 9.62891V59.3711C63.8026 64.1884 59.9193 68.0855 55.1192 68.0855Z"
          fill="url(#paint7_linear_1366_38919)"
        />
        <path
          d="M86.0908 68.1126C84.9582 68.1126 83.7986 67.8826 82.6794 67.409C78.2703 65.5145 76.2208 60.3996 78.1085 55.9747L99.2912 6.20544C101.179 1.7806 106.276 -0.276206 110.685 1.61822C115.094 3.51265 117.143 8.6276 115.256 13.0524L94.073 62.8217C92.6707 66.137 89.4617 68.1126 86.0773 68.1126H86.0908Z"
          fill="url(#paint8_linear_1366_38919)"
        />
        <path
          d="M97.2418 58.5187H119.031C123.346 58.5187 126.838 55.014 126.838 50.6839C126.838 46.3538 123.346 42.8491 119.031 42.8491H97.2418C92.927 42.8491 89.4348 46.3538 89.4348 50.6839C89.4348 55.014 92.927 58.5187 97.2418 58.5187Z"
          fill="url(#paint9_linear_1366_38919)"
        />
        <path
          style={{ mixBlendMode: 'multiply' }}
          d="M108.136 42.8625L114.797 58.5186H119.098L112.384 42.8625H108.136Z"
          fill="url(#paint10_linear_1366_38919)"
        />
        <path
          d="M128.47 68.1126C129.602 68.1126 130.762 67.8826 131.881 67.409C136.29 65.5145 138.34 60.3996 136.452 55.9747L115.269 6.20544C113.382 1.7806 108.285 -0.276206 103.876 1.61822C99.4666 3.51265 97.4171 8.6276 99.3048 13.0524L120.487 62.8217C121.89 66.137 125.099 68.1126 128.483 68.1126H128.47Z"
          fill="url(#paint11_linear_1366_38919)"
        />
        <path
          d="M105.979 28.7356C104.617 25.4338 101.408 23.2688 97.835 23.2688C94.2619 23.2688 91.0528 25.4338 89.6909 28.7356L99.2912 6.17834H105.116L105.979 28.722V28.7356Z"
          fill="url(#paint12_linear_1366_38919)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1366_38919"
            x1="206.715"
            y1="22.0645"
            x2="175.15"
            y2="22.0645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00DEFF" />
            <stop offset="1" stopColor="#0078FF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1366_38919"
            x1="206.715"
            y1="46.9356"
            x2="175.15"
            y2="46.9356"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00DEFF" />
            <stop offset="1" stopColor="#0078FF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1366_38919"
            x1="155.625"
            y1="69.6281"
            x2="175.238"
            y2="-0.0639962"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1366_38919"
            x1="7.06387"
            y1="62.4022"
            x2="10.4722"
            y2="21.038"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00DEFF" />
            <stop offset="1" stopColor="#0078FF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1366_38919"
            x1="23.5408"
            y1="22.0644"
            x2="63.8027"
            y2="22.0644"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00DEFF" />
            <stop offset="1" stopColor="#0078FF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1366_38919"
            x1="2.96468"
            y1="0.535644"
            x2="35.0012"
            y2="38.9616"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1366_38919"
            x1="-9.19735"
            y1="10.6709"
            x2="22.8527"
            y2="49.1103"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_1366_38919"
            x1="60.971"
            y1="65.2439"
            x2="47.1095"
            y2="-7.02384"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_1366_38919"
            x1="85.7267"
            y1="78.329"
            x2="98.9479"
            y2="25.8485"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00DEFF" />
            <stop offset="1" stopColor="#0078FF" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_1366_38919"
            x1="126.838"
            y1="50.6839"
            x2="89.4348"
            y2="50.6839"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00A4FF" />
            <stop offset="1" stopColor="#00D0FF" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_1366_38919"
            x1="116.186"
            y1="49.5878"
            x2="111.637"
            y2="51.5111"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00E4FF" />
            <stop offset="1" stopColor="#00FFFF" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_1366_38919"
            x1="101.799"
            y1="63.7013"
            x2="129.903"
            y2="12.9883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_1366_38919"
            x1="120.501"
            y1="59.4253"
            x2="92.3832"
            y2="8.69871"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#00C0FF" />
            <stop offset="1" stopColor="#00EAFF" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default MaxLogoIcon;
