module.exports = [{
      plugin: require('../plugins/gatsby-theme-zefr/gatsby-browser.ts'),
      options: {"plugins":[],"version":"0.2.12"},
    },{
      plugin: require('../plugins/gatsby-plugin-zefr-auth0/gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zefr Tools","short_name":"Zefr","start_url":"/","lang":"en","background_color":"#31363A","theme_color":"#31363A","display":"standalone","icon":"src/images/zefr-logo.png","theme_color_in_head":false,"crossOrigin":"use-credentials","legacy":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"2df9284c0cda6ce0c620cd8cc4b6be4f"},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
